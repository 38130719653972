.biblio-payment {
  position: absolute;
  bottom: 70%;
  right: 6em;
}

#assign-records-view table {
  background-color: white;
}

#assign-records-view .batch-card {
  display:flex;
  justify-content: space-between;
}

#research-location-input.input-container{
  position:relative;
}

#research-location-input .input-icon{
  position: absolute;
  right: 9px;
  top: 30%;
}

#locationList {
  height:15vh;
  overflow-y: auto;
}

.nounderline {
  text-decoration: none !important
}

.card-default {
  color: #212529;
  text-decoration: none;
  box-shadow: 1px 1px 1px 1px #888888;

}

.card-clickable {
  color: #212529;
  text-decoration: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.card-clickable:hover {
  text-decoration: none;
  box-shadow: 1px 1px 1px 1px #888888;
}

.datatable {
  &-clickable-row {
    cursor: pointer;
  }
}

.datatable-header {
  border: none !important;
}

.datatable-caret {
  margin-top:-16px;
}

.datatable-body{
  border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
}

.datatable tr:nth-child(1) td{
  border-top:none;
}
.datatable tr:nth-child(1) td:first-child {
  border-top-left-radius: 0.25rem;
}

.datatable tr:nth-child(1) td:last-child {
  border-top-right-radius: 0.25rem;
}

.datatable tr:last-child td:first-child {
  border-bottom-left-radius: 0.25rem;
}

.datatable tr:last-child td:last-child {
  border-bottom-right-radius: 0.25rem;
}

.datatable .td-style {
  vertical-align: middle;
}

.border-2 {
  border-width:2px !important;
}

.research-card {
  background: #f7f8fa;
  border: none;
}

.text-black {
  color: #000000;
}

.btn-outline-primary {
  background: #ffffff;
}

.btn-outline-inverse {
  @extend .btn-outline-primary;
  color: $light;
  background: #006b75;
  border: solid 1px $light;

  &.active {
    color: $primary;
    background: $white;
    border: solid 1px $white;
  }

  &:not(:disabled):not(.disabled).active {
    color: $primary;
    background: $white;
    border: solid 1px $white;
  }

  &:hover {
    color: $primary;
    background: $white;
    border: solid 1px $white;
  }
}

.btn.btn-outline-primary.disabled.active{
  color: $light !important;
  background: $primary !important;
  border: solid 1px $light !important;

}

.bg-research-sidebar {
  background-color: #006B75 !important;
  height: calc(100vh - 59px);
  overflow-y: auto;
}

.bg-disabled {
  background-color: #00525A !important;
  border: #00525A !important;
}

label {
  margin-bottom: 0;
}


.search_group{
  background-color: #ffffff;
}

.search-text-1 {
  color: #78797C;
  font-size: 14px;
  line-height: 20px;
}

.search-text-2 {
  color: #78797C;
  font-size: 12px;
}

.find-a-record {
  color: #272C30;
  font-size: 28px;
  font-weight: bold;
  line-height: 38px;
}

#search-result {
  overflow-y: auto;
  height:calc(30vh);
  overflow-x: hidden;
}

.record-link {
  color: #00838F !important;
  font-size: 14px;
  line-height: 20px;
}

.find-a-record-modal {
  max-width: 610px;
  margin: 1.75rem auto;
}

.result {
  background-color: #ffffff;
  border-right: #78797C 1px solid;
  height: 600px;
  overflow-y: scroll !important;
  overflow-x: hidden;
}

.word-card-span {
  color: #78797C;
  font-size: 14px;
}

.search-modal {
  max-width: 80%;
  position: relative;
}

.work-detail{
  color: #00838F;
}

.loader {
  text-align: center;
  opacity: 0.7;
  filter: alpha(opacity=20);
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #31394847;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

.spinner-border {
  width: 40px;
  height: 40px;
  margin-top: 23% !important;
}

.book {
  color: #DC266E;
  background-color: #FFF0FA;
}

.journal {
  color: #F53544;
  background-color: #FFF5F5;
}

.newspaper {
  color: #219AD9;
  background-color: #E2EDFE;
}

.other {
  color: #F5B620;
  background-color: #fef7e2;
}

.magazine {
  color: #FA6400;
  background-color: #feebe2;
}

.website {
  color: #008943;
  background-color: #e7fee2;
}


.rectangle-2 {
  height: 30px;
  width: 55px;
  text-align: center;
  border-radius: 15px;
  font-size: 14px;
}

.badge {
  &-query-type {
    font-weight: initial;
    font-size: inherit;
    width: 9em;

    &-work-request {
      color: $white;
      background-color: $pink;
    }

    &-research-audit {
      color: $white;
      background-color: $indigo;
    }
  }

  &-user-role {
    font-weight: initial;
    font-size: initial;
    padding: 0.25em 0.75em;

    &-researcher {
      background-color: lighten($yellow, 35%);
    }

    &-coordinator {
      background-color: lighten($blue, 35%);
    }

    &-external-auditor {
      background-color: lighten($purple, 35%);
    }

    &-it {
      background-color: lighten($black, 35%);
    }
  }
}

.navbar {
  z-index: 10;
}

.occupy-viewport {
  min-height: 100vh;
}

.query-action-comment {
  color: $black;
  &-author-name {
    font-weight: bold;
  }
}

.work-link-icon {
  border-left: 0;
  background-color: #ffffff;
  color: #6c757d70;
}

.link-active {
  color: #105CCF;
  cursor: pointer;
}

.readonly {
  background-color: #e9ecef !important;
}

.tool-tip{
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1em;
  text-align: center;
  color: #ffffff;
  background: $secondary;
  border-radius:30px;
  margin-left: 0.25em;

  &-warning {
    background: $yellow;
  }

  &-error{
    background: $red;
  }

  &-info {
    font-size:0.8em;
    width:1.5em;
    height:1.5em;
    line-height: 1.5em;
  }

  &-info-PC {
    background: $blue;
  }

  &-info-AC {
    background: $green;
  }
}


.biblio-link-card {
  margin-bottom: 1rem;
  border: 4px solid transparent;
  border-radius: 8px;

  &-left {
    background: #b9dbdd;
  }

  &-right {
    background: #a7c4c6;
    text-align: center;
  }

  &.active {
    .biblio-link-card-left {
      background: $white;
    }

    .biblio-link-card-right {
      background: #dee2e6;
      text-align: center;
    }
  }
}

.usage-link-card {
  margin-bottom: 1rem;
  border: 4px solid transparent;
  border-radius: 8px;

  &-left {
    background: #a7c4c6;
    text-align: center;
  }

  &-center {
    background: #b9dbdd;
  }

  &-right {
    background: #b9dbdd;
  }

  &.active {
    .usage-link-card-left {
      background: #dee2e6;
      text-align: center;

    }

    .usage-link-card-center {
      background: $white;
    }

    .usage-link-card-right {
      background: $white;
    }
  }
}

.link-marker {
  color: #7a7e7e;
  font-size: 1.5rem;
  margin-left: -0.25rem;

  &>i.active {
    color: #00838f;
  }
}

.hover {
  border: 4px solid #ffa500d9;
  border-radius: 8px;
  background: #d5f3f3;
}

.status-border {
  border: 2px solid #6c757d6b;
}

.work-detail-card {
  background-color: #ffffff;
  height: 689px;
  overflow-y: scroll !important;
  overflow-x: hidden;
}

.work-search-modal {
  height: 97% !important;
}

.flag-badge {
  color: #00838f;
  background: #fff;
  border: solid 1px #00838f;;
}

#work_details > tr > td{
  padding: 0;
}

.fixed-width {
  width: calc(20vh) !important;
}

#recipient-details > tr > td {
  padding: 0;
}

#contribution-details > tr > td {
  padding: 0;
}

.pdf-view-background {
  background: rgba(0,0,0,0.8);
}

.pdf-view {
  height: calc(100vh);
  overflow-y:auto;
}

.document-view {
  height: calc(100vh);
}

.thumbnails-view {
  background:rgba(0,0,0,0.9);
  height: calc(100vh);
  padding-left:10px;
  overflow-y:auto;
  overflow-x:hidden;
}

.thumbnail-pdf-view {
  width: 80%;
  :hover {
    border: 4px solid #ffa500d9;
  }
  &.active {
    border: 3px solid #ffa500d9;
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.biblio-tag {
  color: #ffffff;
  background: #fff;
  border: solid 2px #ffffff;;
}

.biblio-tag-rectangle {
  height: 42px;
  width: 87px;
  text-align: center;
  border-radius: 26px;
  font-size: 20px
}

.tag-active-biblio {
  background-color: #20c93c;
}

.tag-other-biblio {
  background-color: #105CCF;
}

.page-tagged-active {
  content:"";
  position: absolute;
  background: rgba(33, 131, 26, 0.63);
  z-index:999;
}

.page-tagged-active > h3 {
  margin-top: 19rem !important;
}

.page-tagged-other {
  content:"";
  position: absolute;
  background: #105ccf9e;
  z-index:999;
}

.page-tagged-other > h3 {
  margin-top: 19rem !important;
}
.seperate-line {
  clear:both;
  display:block;
  width: 96%;
  background-color:lightgray;
}

.grouped-index {
  content:"";
  position: absolute;
  background: rgba(207, 43, 13, 0.81);
  z-index:999;
}

.grouped-index > h3 {
  margin-top: 19rem !important;
}

.bulk-edit-sidebar {
  background-color: $white !important;
  height: calc(92vh) !important;
  overflow-y: auto;
  box-shadow: inset -0.125rem .125rem rgba(0,0,0,.075) !important;
}

.button-tabs {
  width: 100%;

  &--tall {
    .button-tab {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
}

.column-list {
  max-height: 14rem;
}

.bulk-edit-filter {
  margin: 1rem 0;
  border: solid 1px #00838f;
  border-radius: 4px;
  padding: 1rem;
}

.bulk-edit-filter-string-value {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: 1.5;
  padding: 6px 12px;
  border: solid 1px #ced4da;
  border-radius: 4px;
}

.button-tab {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.heldback-font{
  color: #ffa500d9 !important;
  font-size: 24px;
}

#dough-nut-chart .donut-inner {
  text-align: center;
  margin-left:-50px;
  display: flex;
  width: 100%;
  h1 {
    font-size: 65px;
    line-height: 65px;
  }
  h5 {
    margin-bottom: 0;
  }
  span {
    font-size: 30px;
    line-height: 30px;
    vertical-align: middle;
  }
}

#statistics {
  #summary_info span, #individualProcess span{
    font-size: 26px;
    color: #888;
  }
  .nav-item .active {
    background-color: $primary !important;
    color: white !important;
    font-size:18px;
    font-weight: bold;
  }

  .nav-tabs .nav-link {
    border-bottom: none;
  }
}
.bulk-edit-modal {
  height: calc(100vh - 200px);
}

.bulk-edit-card {
  background-color: #6c757d1a;
  border: 0 !important;
  padding: 0 !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #00838f !important;
}

.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  background-color: #ffffff;
}

span.current {
  color: #ffffff;
}

.shadow {
  width: 80%;
  height: 300px !important;
}

#query-result {
  height: calc(77vh);
}

#sidebar {
  display: none;
}

#sidebar.active {
  margin-left: 0;
  display: block !important;
}

.wordBreak {
    word-break: break-word;
}

#moreFields {
  color: #00838f;
  text-decoration: none;
  cursor: pointer;
}

.table-sorting {
    line-height: 80%;
    width:10px;
}

.bootstrap-select .dropdown-menu li a span.text {
    width: 500px;
}
